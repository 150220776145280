import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./modules/nav/NavBar";
import Hero from "./modules/hero/Hero";
import "bootstrap/dist/css/bootstrap.min.css";
import CardSection from "./modules/features/cards/CardSection";
import WhyChoose from "./modules/why/WhyChoose";
import OrderCard from "./modules/order-card/OrderCard";
import HowItWorksSection from "./modules/how-it-works/HowItWorksSection";
import TestimonialsSection from "./modules/words-from-customers/TestimonialsSection";
import ContactlessSection from "./modules/contactless-payments/ContactlessSection";
import FAQSection from "./modules/faq/FAQSection";
import Footer from "./modules/footer/Footer";

export function loadAndSetScript(innerHtml: any, position: any, id: any) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.type = "text/javascript";
  script.innerHTML = innerHtml;
  position.appendChild(script);
}

const setHeap = () => {
  if (typeof window !== "undefined") {
    if (!document.querySelector("#heap")) {
      loadAndSetScript(
        `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load(1626918332);`,
        document.querySelector("head"),
        "heap"
      );
    }
  }
};

function App() {
  useEffect(() => {
    setHeap();
  }, []);
  return (
    <div>
      <Navbar />
      <Hero />
      <CardSection />
      <WhyChoose />
      <OrderCard />
      {/*/!*<HowItWorksSection />*!/*/}
      {/*/!*<TestimonialsSection />*!/*/}
      <ContactlessSection />
      {/*/!*<FAQSection />*!/*/}
      <Footer />
    </div>
  );
}

export default App;
