import React from "react";
import { Accordion, Card, Col, Container, Row } from "react-bootstrap";
import styles from "./WhyChoose.module.scss";
import RightSideImgSrc from "../assets/RightSideImage.png";
import { ReactComponent as StarIcon } from "../assets/StarIcon.svg";

const WhyChoose: React.FC = () => {
  const accordionItems = [
    {
      id: "0",
      title: "Lifestyle perks",
      content:
        "Enjoy exclusive discounts and offers from our partner merchants, including fitness centers, restaurants, and more.",
    },
    {
      id: "1",
      title: "Security first: alerts, and fraud protection",
      content:
        "Real-time transaction alerts to keep you informed about your spending. Fraud protection to keep your account safe.",
    },
    {
      id: "2",
      title: "Automatic savings/investing",
      content:
        "Our card features automatic savings and investing options that help you reach your financial goals faster. Simply set a savings goal, and watch your money grow.",
    },
    {
      id: "3",
      title: "Hassle-free management",
      content:
        "With our card, you can easily manage your finances through our user-friendly mobile app. Keep track of your spending, view your rewards, and make payments – all in one place.",
    },
    {
      id: "4",
      title: "Global acceptance",
      content:
        "Our card is accepted worldwide, so you can use it wherever you go. We also support Google and Apple Wallets",
    },
  ];

  return (
    <section className={styles.whyChooseSection}>
      <div>
        <div
          className={`d-flex flex-row justify-content-between ${styles.whyChooseDiv}`}
        >
          <div className={`d-flex flex-column ${styles.textAccordionDiv}`}>
            <h2 className={styles.freelancerTitle}>
              Why choose the Freelancer Cash Card?
            </h2>
            <Accordion className={styles.accordion}>
              {accordionItems.map((item) => (
                <Accordion.Item
                  eventKey={item.id}
                  className={styles.accordionItem}
                >
                  <Accordion.Header className={styles.accordionHeader}>
                    <StarIcon style={{ marginRight: 12 }} />
                    <span>{item.title}</span>
                  </Accordion.Header>
                  <Accordion.Body className={styles.accordionBody}>
                    {item.content}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-end">
              <img
                src={RightSideImgSrc}
                alt="Right Side Image"
                className={styles.rightImage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChoose;
