import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./ContactlessSection.module.scss";
import SurveyButton from "../common/SurveyButton/SurveyButton";
import ContactlessImgSrc from "../assets/contactless-img.png";

const ContactlessSection: React.FC = () => {
  return (
    <section className={styles.contactlessSection}>
      <div>
        <div
          className={`d-flex flex-row justify-content-between w-100 ${styles.contactlessDiv}`}
        >
          <div className={`d-flex flex-column ${styles.siteContent}`}>
            <h2 className={styles.contactlessTitle}>
              Contactless payments for quick and easy transactions.
            </h2>
            <p className={styles.subtext}>
              With our card, you can easily manage your finances through our
              user-friendly mobile app. Keep track of your spending, view your
              rewards, and make payments – all in one place.
            </p>
            <SurveyButton text="Join The Waitlist" backgroundColor={"red"} />
          </div>
          <div className={`d-flex flex-column ${styles.contactlessImgDiv}`}>
            <img
              src={ContactlessImgSrc}
              alt="Contactless payment"
              className={styles.rightImage}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactlessSection;
