import React from "react";
import styles from "./SurveyButton.module.scss";

interface CustomButtonProps {
  text: string;
  backgroundColor: string;
  customStyle?: string;
}

const SurveyButton: React.FC<CustomButtonProps> = ({ text, customStyle }) => {
  const handleClick = () => {
    if (window.Intercom) {
      window.Intercom("showNewMessage", "Please take our survey!");
    }
  };

  return (
    <a
      className={`${styles.customButton} ${customStyle}`}
      onClick={handleClick}
      href={"https://1ngz9fodi95.typeform.com/alpine-empower"}
      target={"_blank"}
      rel={"noreferrer"}
    >
      {text}
    </a>
  );
};

export default SurveyButton;
