import React from "react";
import styles from "./NavBar.module.scss";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import SurveyButton from "../common/SurveyButton/SurveyButton";

const Navbar: React.FC = () => {
  return (
    <header className={styles.navbar}>
      <div className={styles.logoContainer}>
        <Logo width={247} height={41} />
      </div>
      <div className={styles.buttonContainer}>
        <SurveyButton
          text={"Join The Waitlist"}
          backgroundColor={"Green"}
          customStyle={styles.buttonCustomStyle}
        />
      </div>
    </header>
  );
};

export default Navbar;
