import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Footer.module.scss";
import { ReactComponent as Logo } from "../assets/Logo.svg";

const Footer: React.FC = () => {
  const socialLinks = [
    {
      name: "Facebook",
      icon: "path/to/facebook/icon.svg",
      url: "https://www.facebook.com/",
    },
    {
      name: "Twitter",
      icon: "path/to/twitter/icon.svg",
      url: "https://www.twitter.com/",
    },
    {
      name: "Instagram",
      icon: "path/to/instagram/icon.svg",
      url: "https://www.instagram.com/",
    },
    // Add more social media icons and links as needed
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles.footerDiv}>
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row" style={{ marginBottom: 20 }}>
              <Logo />
            </div>
            <p className={styles.boldSubtext}>Bank from anywhere, 24/7</p>
            {/*<p>*/}
            {/*  Download the Alpine app today for a 100% mobile banking*/}
            {/*  experience. Available on iOS and Android.*/}
            {/*</p>*/}
            {/*<span className={styles.comingSoon}>Coming Soon</span>*/}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between flex-wrap-reverse">
          <p>© Copyright 2023 - Alpine Empower</p>
          <p>Contact Us: fahim@tryalpine.com</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
