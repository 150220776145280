import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./OrderCard.module.scss";
import SurveyButton from "../common/SurveyButton/SurveyButton";
import ManHoldingCardSrc from "../assets/ManHoldingCard.png";

const OrderCard: React.FC = () => {
  const featureCards = [
    {
      imageSrc: `${process.env.PUBLIC_URL}/order-card-images/lockup.svg`,
      title: "No Lockup",
      subtext:
        "Unlike some other banking options, there are no lockup periods with the Freelancer Cash Card. You have full control over your funds and can access them whenever you need.",
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/order-card-images/secure.svg`,
      title: "FDIC Insured",
      subtext:
        "Your funds are FDIC insured up to $250,000. This means that in the unlikely event that Freelancer Cash Card were to become insolvent, your money would be protected by the Federal Deposit Insurance Corporation (FDIC).",
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/order-card-images/secure.svg`,
      title: "Secure Transactions",
      subtext:
        "All transactions on the Freelancer Cash Card are protected by advanced encryption technology, ensuring that your sensitive information is kept safe from prying eyes.",
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/order-card-images/fraud.svg`,
      title: "Fraud Protection",
      subtext:
        "In the unlikely event that your Freelancer Cash Card is lost or stolen, we have robust fraud protection measures in place to prevent unauthorized transactions and to reimburse you for any fraudulent charges.",
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/order-card-images/privacy.svg`,
      title: "Privacy Protection",
      subtext:
        "We are committed to protecting your personal information and will never share or sell your data to third parties without your consent.",
    },
    // Add more feature cards as needed
  ];

  return (
    <section className={styles.cardSection}>
      <Container>
        <Row>
          <Col md={6}>
            <h2>The only card that does it all</h2>
            <p>
              With our card, you can easily manage your finances through our
              user-friendly mobile app. Keep track of your spending, view your
              rewards, and make payments – all in one place.
            </p>
            <SurveyButton text="Join The Waitlist" backgroundColor="blue" />
            <img
              src={ManHoldingCardSrc}
              alt="Left Side Image"
              className={styles.leftImage}
            />
          </Col>
          <Col md={6}>
            <Row>
              <Col className={styles.centeredColumn}>
                {/* Render the first 2 cards */}
                {featureCards.slice(0, 2).map((card, index) => (
                  <div key={index} className={styles.card}>
                    <div className="w-100 d-flex flex-row justify-content-start">
                      <img
                        src={card.imageSrc}
                        alt={card.title}
                        style={{ height: "48px", width: "48px" }}
                      />
                    </div>

                    <h3 className={styles.cardtitle}>{card.title}</h3>
                    <p className={styles.cardSubtext}>{card.subtext}</p>
                  </div>
                ))}
              </Col>
              <Col className={styles.centeredColumn}>
                {/* Render the next 3 cards */}
                {featureCards.slice(2).map((card, index) => (
                  <div key={index} className={styles.card}>
                    <img
                      src={card.imageSrc}
                      alt={card.title}
                      style={{ height: "48px", width: "48px" }}
                    />
                    <h3>{card.title}</h3>
                    <p>{card.subtext}</p>
                  </div>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OrderCard;
