import React from "react";
import styles from "./CardSection.module.scss";

const CardSection: React.FC = () => {
  const featureCards = [
    {
      imageSrc: `${process.env.PUBLIC_URL}/card-images/five-percent.svg`,
      title: "High Cashback Rewards",
      subtext:
        "Our card offers up to 5% cashback on select categories, such as travel, dining, and shopping. You can earn even more cashback by referring friends to the card.",
      centerImage: true,
      imageHeight: "64px",
      imageWidth: "130px",
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/card-images/contactless.svg`,
      title: "Contactless Payments",
      subtext:
        "Make payments quickly and easily with our contactless payment feature and Apple Pay, Google Pay support.",
      centerImage: true,
      imageHeight: "190px",
      imageWidth: "252px",
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/card-images/globe.svg`,
      title: "Global Acceptance",
      subtext:
        "Our card is accepted worldwide, so you can use it wherever you go. It can also be added to Apple and Google Wallets",
      centerImage: false,
      imageHeight: "100%",
      imageWidth: "100%",
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/card-images/customer-support.svg`,
      title: "24/7 Customer Support",
      subtext:
        "Get assistance anytime with our round-the-clock customer support.",
      centerImage: false,
      imageHeight: "100%",
      imageWidth: "100%",
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/card-images/lock.svg`,
      title: "Easy Lock and Unlock",
      subtext:
        "Keep your card secure with the ability to easily lock and unlock it at any time.",
      centerImage: false,
      imageHeight: "100%",
      imageWidth: "100%",
    },
  ];

  return (
    <section className={styles.featuresSection}>
      {/*<h2>*/}
      {/*  Everything you need in a Freelance Cash Card — from startup to*/}
      {/*  enterprise*/}
      {/*</h2>*/}
      <div
        className="d-flex flex-row justify-content-center flex-wrap"
        style={{ gap: 25 }}
      >
        {featureCards.map((card, index) => (
          <div key={index} className={styles.card}>
            <div
              className={`${styles.imgDiv} ${
                card.centerImage ? styles.centered : ""
              }`}
            >
              <img
                src={card.imageSrc}
                alt={card.title}
                className={`${styles.cardImage}`}
                height={card.imageHeight}
                width={card.imageWidth}
                style={{
                  height: card.imageHeight,
                  width: card.imageWidth,
                  borderRadius: card.centerImage ? "0" : "16px",
                }}
              />
            </div>

            <h3 className={styles.titleName}>{card.title}</h3>
            <p className={styles.subtext}>{card.subtext}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CardSection;
