import React from "react";
import styles from "./Hero.module.scss";
import SurveyButton from "../common/SurveyButton/SurveyButton";
import { ReactComponent as HeroGraphic } from "../assets/HeroGraphic.svg";
import { ReactComponent as BlueStar } from "../assets/Hero/blue-star.svg";
import { ReactComponent as GreenStar } from "../assets/Hero/green-star.svg";

const Hero: React.FC = () => {
  return (
    <section
      className={styles.hero}
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/glow-bg.svg)` }}
    >
      <div className={`d-flex justify-content-center w-100 ${styles.heroDiv}`}>
        <div className={styles.heroContent}>
          <h1 className={styles.heroText}>
            Welcome to Alpine Empower – The Borderless Card for Global Professionals!
            <BlueStar className={styles.blueStar} />
            <GreenStar className={styles.greenStar} />
          </h1>
          <h2 className={styles.heroSubText}>
            With our card, you can enjoy high cashback rewards, lifestyle perks,
            and automatic savings/investing in USD. Say goodbye to the hassle of
            managing multiple accounts, and hello to an all-in-one solution that
            helps you save time and money.
          </h2>
          <SurveyButton
            text={"Join The Waitlist"}
            backgroundColor={
              "linear-gradient(180deg, #EBFFBB 0%, #C2FF2E 100%)"
            }
          />
          <div style={{ height: 73 }} />
        </div>
        <div className={styles.heroGraphicDiv}>
          <HeroGraphic />
        </div>
      </div>
    </section>
  );
};

export default Hero;
